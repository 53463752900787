<template>
	<div class="" style="min-width: 1200px">
		<div class="gg-container">
			<div class="content">
				<div class="nav-left" style="min-width: 150px">
					<div :class="['nav-left-box',menuIndex==item.value?'active':'']" v-for="item in menuOptions"
							 v-bind:key="item.value">
						<i :class="['iconfont',item.icon,menuIndex==item.value?item.bg:'']"></i>
						<div class="nav-left-item" @click="selectMenu(item)" style="font-size: 14px">{{item.label}}</div>
					</div>
				
				</div>
				
				<div class="nav-right">
					<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
						<el-menu-item v-for="item in navs" v-bind:key="item.id" :index="item.id">{{item.label}}
						</el-menu-item>
					</el-menu>
					<dictsList :id="activeIndex" :title="title" :isCode="isCode" :isStatus="isStatus"></dictsList>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import dictsList from './components/dictsList.vue'

export default {
  name: "dataDicts",
  components: {
    dictsList
  },

  created () {
    this.navs = this._getNavList()
    this._setDictsListProp()
  },
  data () {
    return {
      curIcon: 'icon-binlixinxi',
      navs: [],
      menuIndex: 1,
      activeIndex: 1,
      pageTitle: '病历信息',
      navTitles: [{
        id: 1,
        label: '基本诊断',
        isCode: true,
        isStatus: true,
        menuI: 1
      },
        {
          id: 3,
          label: '既往史',
          isCode: false,
          isStatus: true,
          menuI: 1
        },
        {
          id: 4,
          label: '过敏史',
          isCode: false,
          isStatus: true,
          menuI: 1
        },
        {
          id: 5,
          label: '用药途径',
          isCode: true,
          isStatus: true,
          menuI: 2
        },
        {
          id: 6,
          label: '用药频率',
          isCode: true,
          isStatus: true,
          menuI: 2
        },
        {
          id: 7,
          label: '用药禁忌',
          isCode: true,
          isStatus: true,
          menuI: 2
        },
        {
          id: 8,
          label: '包装单位',
          isCode: true,
          isStatus: true,
          menuI: 2
        },
        {
          id: 9,
          label: '药品剂型',
          isCode: true,
          isStatus: true,
          menuI: 2
        },
        // {
        // 	id: 10,
        // 	label: '药品类型',
        // 	isCode: true,
        // 	isStatus: true,
        // 	menuI: 2
        // },
        {
          id: 11,
          label: '执业级别',
          isCode: true,
          isStatus: true,
          menuI: 3
        },
        {
          id: 12,
          label: '执业类别',
          isCode: true,
          isStatus: true,
          menuI: 3
        },
        {
          id: 13,
          label: '执业范围',
          isCode: true,
          isStatus: true,
          menuI: 3
        },
        {
          id: 14,
          label: '医师职称',
          isCode: true,
          isStatus: true,
          menuI: 3
        },
        {
          id: 15,
          label: '民族',
          isCode: true,
          isStatus: true,
          menuI: 4
        },
        {
          id: 16,
          label: '性别',
          isCode: true,
          isStatus: true,
          menuI: 4
        },
        {
          id: 17,
          label: '学历',
          isCode: true,
          isStatus: true,
          menuI: 4
        },
      ],
      menuOptions: [{
        value: 1,
        label: '病历信息',
        curIndex: 1,
        icon: 'icon-binlixinxi',
        bg: 'bg1'
      },
        {
          value: 2,
          label: '药品信息',
          curIndex: 5,
          icon: 'icon-yaopinxinxi',
          bg: 'bg2'
        },
        {
          value: 3,
          label: '医师信息',
          curIndex: 11,
          icon: 'icon-yishiyishi',
          bg: 'bg3'
        },
        {
          value: 4,
          label: '公共信息',
          curIndex: 15,
          icon: 'icon-gonggongxinxi',
          bg: 'bg4'
        },
      ],
      isCode: true,

    }
  },
  methods: {
    _getNavList () {
      return this.navTitles.filter((item) => {
        return item.menuI == this.menuIndex
      })
    },
    _setDictsListProp () {
      let curTitle = this.navTitles.find(v => v.id == this.activeIndex)
      this.title = curTitle.label
      this.isCode = curTitle.isCode
      this.isStatus = curTitle.isStatus
    },
    selectMenu (row) {
      this.menuIndex = row.value
      this.pageTitle = row.label
      this.activeIndex = row.curIndex
      this._setDictsListProp()
      this.curIcon = row.icon
      this.navs = this._getNavList()
    },
    handleSelect (e) {
      this.activeIndex = e
      this._setDictsListProp()
    }

  }
}
</script>

<style scoped lang="scss">
	
	.gg-container {
		position: relative;
	}
	
	.dot {
		display: inline-block;
		border: 5px solid;
		border-radius: 50%;
		margin: 0 5px;
	}
	
	/*.title-icon {*/
	/*	margin-right: 15px;*/
	/*	color: #fff;*/
	/*	font-size: 18px;*/
	/*	padding: 5px;*/
	/*	border-radius: 2px;*/
	/*	background: #2362fb;*/
	/*}*/
	
	.content {
		display: flex;
		
		.nav-left {
			background: #fff;;
			width: 220px;
			border-radius: 10px;
			box-shadow: 1px 1px 3px #f9f9f9;
			margin-right: 20px;
			padding-top: 20px;
			
			.nav-left-box {
				display: flex;
				align-items: center;
				padding-left: 25px;
				box-sizing: border-box;
				
				i {
					font-size: 12px;
					padding: 5px;
					border-radius: 2px;
					background: rgb(237, 242, 246);
					color: #8a94a6;
				}
				
				.bg1 {
					color: #fff;
					background: rgb(39, 186, 74);
				}
				
				.bg2 {
					color: #fff;
					background: rgb(25, 181, 246);
				}
				
				.bg3 {
					color: #fff;
					background: rgb(112, 74, 253);
				}
				
				.bg4 {
					color: #fff;
					background: rgb(255, 185, 64);
				}
				
				.nav-left-item {
					height: 55px;
					line-height: 55px;
					text-align: center;
					font-size: 16px;
					cursor: pointer;
					margin-left: 15px;
				}
				
			}
			
			.nav-left-box:hover {
				background: #F6F8FA;
			}
			
			.active {
				background: #F6F8FA;
			}
			
		}
		
		.nav-right {
			background: #fff;
			width: 100%;
		}
	}
</style>
