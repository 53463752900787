<template>
  <div class="gg-container">
    <div class="search-container">
      <div class="search-container-fn-input">
        <el-input
          size="small"
          placeholder="关键字搜索"
          prefix-icon="el-icon-search"
          v-model="searchParams.name"
          clearable
          @change="getList('rest')"
        >
        </el-input>
      </div>
      <div
        class="search-container-fn-input"
        v-if="operateList.indexOf('delete') > -1"
      >
        <el-button
          size="mini"
          type="success"
          icon="el-icon-plus"
          style="padding: 7px 8px;margin-left: 5px;"
          @click="editItem('add')"
        >
          <span style="font-size: 12px">新增</span>
        </el-button>
      </div>
      <div
        class="search-container-fn-input"
        v-if="operateList.indexOf('delete') > -1"
      >
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-refresh"
          style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
          @click="delItem(multipleSelectionPres[0])"
          v-if="multipleSelectionPres.length == 1"
          >删除
        </el-button>
      </div>
      <div
        class="search-container-fn-input"
        v-if="operateList.indexOf('update') > -1"
      >
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-refresh"
          style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
          @click="editItem('edit', multipleSelectionPres[0])"
          v-if="multipleSelectionPres.length == 1"
          >编辑
        </el-button>
      </div>
      <div
        class="search-container-fn-input"
        v-if="operateList.indexOf('update_table') > -1"
      >
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-edit-outline"
          style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
          @click="handleEditTableHead"
          >编辑表头
        </el-button>
      </div>
      <div
        class="search-container-fn-input"
        v-if="operateList.indexOf('reset_query') > -1"
      >
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-refresh"
          style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
          @click="handleResetSearch"
          >重置查找
        </el-button>
      </div>
    </div>
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="575"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="editArticle('view', scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              @change="changeStatus($event, scope.row)"
              v-model="scope.row.status"
              class="switch"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'group_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.group_name)
                "
                v-model="searchParams.group_id"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'trigger' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.trigger)"
                v-model="searchParams.trigger"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'from_type' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.from_type)
                "
                v-model="searchParams.from_type"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in from_type_List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'client' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.client)"
                v-model="searchParams.client_type"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="(item, index) in clientList"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-dialog
      :title="statusTitle + title"
      :visible.sync="dialogVisible"
      width="640px"
      v-if="dialogVisible"
    >
      <span
        slot="title"
        style="font-size: 14px;color: #606266;font-weight: 700"
        >{{ statusTitle + title }}</span
      >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="80px"
        :rules="rules"
        class="demo-ruleForm"
        size="mini"
        :inline="true"
        label-position="top"
      >
        <el-form-item label="名称" prop="name" class="from-item-second">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          v-if="isCode"
          label="编码"
          prop="code"
          class="from-item-second"
        >
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item
          v-if="id == 1"
          label="类型"
          prop="cd_id"
          class="from-item-second"
        >
          <el-select v-model="form.cd_id" placeholder="请选择">
            <el-option
              v-for="item in typePptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort" class="from-item-second">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item
          v-if="isStatus"
          label="状态"
          prop="status"
          class="from-item-second"
        >
          <el-radio
            v-for="item in statusOptions"
            v-model="form.status"
            v-bind:key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="small" @click="saveForm('ruleForm')"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--编辑表头-->
    <dictsListTableHead
      :isActiveDictsListTableHead.sync="isActiveDictsListTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveDictsListTableHead"
      @getList="getList"
    >
    </dictsListTableHead>
  </div>
</template>

<script>
import dictsListTableHead from "../tableHead/dictsListTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import {
  dictsList,
  callMethod,
  dictsStatus,
  diseaseStatus,
} from "@/api/basic/index";
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";
export default {
  name: "dictsList",
  components: {
    Pagination,
    dictsListTableHead,
  },
  props: {
    id: {
      type: [Number, String],
    },
    title: [String],
    isCode: [Boolean],
    isStatus: [Boolean],
  },

  watch: {
    id: {
      handler(n, o) {
        this.cd_id = n;
        this._getAdminFieldIndex();
        this.getList('restPage');
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },

  async mounted() {
    this.cd_id = this.id;
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
    await this._getAdminFieldIndex();
    this.getList('restPage');
  },
  computed:{
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  created() {},
  data() {
    return {
      operateList: [],
      isActiveDictsListTableHead: false,
      multipleSelectionPres: [],
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "appraise_content",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      statusTitle: "新增",
      datetimerange: "",
      searchParams: {},
      currInfo: {
        group_id: null,
        contraindication_name: "",
      },
      dialogVisible: false,
      openType: "",
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        cd_id: "",
      },
      statusOptions: [
        {
          value: true,
          label: "启用",
        },
        {
          value: false,
          label: "禁用",
        },
      ],
      typePptions: [
        {
          value: 1,
          label: "中药诊断",
        },
        {
          value: 2,
          label: "西药诊断",
        },
      ],
      form: {
        status: true,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请填写名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请填写编码",
            trigger: "change",
          },
        ],
        cd_id: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        sort: [
          {
            required: true,
            message: "请填写排序",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
      },
      tableData: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveDictsListTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "dataDicts",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.dataDicts;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    changeStatus(e, row) {
      if (this.id == 1 || this.id == 2) {
        diseaseStatus(row.id, e)
          .then((res) => {
            if (res.code == 200) {
              var msg = e == 1 ? "已启用" : "已禁用";
              return this.$message.success(msg);
            } else {
              return this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        dictsStatus(row.id, e)
          .then((res) => {
            if (res.code == 200) {
              var msg = e == 1 ? "已启用" : "已禁用";
              return this.$message.success(msg);
            } else {
              return this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      console.log(e);
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.limit = this.listQuery.limit;
      params.cd_id = this.cd_id;
      dictsList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    editItem(type, item) {
      this.openType = type;
      if (type === "add") {
        this.statusTitle = "新增";
        this.form = {
          status: true,
        };
      } else {
        this.statusTitle = "修改";
        this.form = {
          ...item,
        };
        console.log(1111111111, item);
        if (!item) {
          this.$message({
            type: "error",
            message: "请选择要编辑的" + this.title,
          });
          return;
        }
      }
      this.dialogVisible = true;
    },
    delItem(item) {
      let params = {};
      let url;
      if (this.id == 1 || this.id == 2) {
        url = `/admin/hospital/disease/${item.id}`;
      } else {
        url = `/admin/hospital/${item.id}`;
      }

      params.id = item.id;
      this.$confirm(`是否删除${this.title}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          callMethod(params, url, "delete")
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.msg + "," + data.code,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },

    saveForm(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          return;
        } else {
          let params = {};
          let url = "";
          params = {
            ...this.form,
          };
          if (this.id != 1) {
            params.cd_id = this.id;
          }
          if (this.openType == "add") {
            url = "/admin/hospital/addDicts"; //添加
          } else {
            //编辑
            if (params.cd_id == 1 || params.cd_id == 2) {
              url = `/admin/hospital/dictsDisease/${params.id}`;
            } else {
              url = `/admin/hospital/dicts/${params.id}`;
            }

            // params.id = this.currInfo.id
          }

          callMethod(params, url, "post")
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: this.statusTitle + this.title + "成功！",
                });
                this.getList();
                this.dialogVisible = false;
              } else {
                this.$message({
                  type: "error",
                  message: data.msg,
                });
              }
            })
            .catch((error) => {
              this.dialogVisible = false;
              console.log(error);
            });
        }
      });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/deep/ .el-form-item__label {
  padding-bottom: 0;
}
/deep/ .el-form-item__content {
  width: 280px;
}

.from-item-second {
  margin-left: 0;
  margin-right: 20px;
}

/deep/ .el-select {
  width: 280px !important;
}

/deep/ .el-date-editor {
  width: 280px !important;
}

/*select 宽度配置*/
/deep/ .el-select {
  width: 85px;
}
</style>
